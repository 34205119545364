import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'

function NotFound() {
  return (
    <DefaultMaster title="Sobre">
      <h1>Página não encontrada</h1>
    </DefaultMaster>
  )
}

export default NotFound
